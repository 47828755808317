import React from 'react';

function AboutUs() {
  return (
    <section id="about" className="mb-20 pt-28 -mt-28">
      <h2 className="font-semibold text-4xl mb-4 text-center md:text-left">About Us</h2>
      <div className="p-4 bg-white shadow-md rounded-md">
        <p>
          本格的な茶室建築でありながら、アットホームな稽古場。今日あった出来事など、会話を楽しみながらの稽古風景。急がずに、それぞれのペースでゆっくりと学んでいく。気がつけば、会話がメインになっていることも、しばしば。「茶道」ってなんでしょう？私は「お茶でも飲みながら、みんなでその場を楽しむ作法」と考える。なんでもルール（作法）がある。そのルールをみんなで学ぶために時間であり、空間。ビシッと和服を着て完璧なお点前をする。みんながそうである必要はない。それぞれが今自分のできることを精一杯やって、その場の雰囲気を楽しくする。それが私の考える茶道。「伝統」なんて、現代人が作り出した観念にすぎない。
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          <img
            src="/images/representative.jpeg"
            alt="Tea Room"
            className="w-full h-72 object-cover rounded-md"
          />
          <img
            src="/images/img10.jpeg"
            alt="Tea Ceremony"
            className="w-full h-72 object-cover rounded-md"
          />
          <img
            src="/images/img12.jpeg"
            alt="Tea Tools"
            className="w-full h-72 object-cover rounded-md"
          />
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
