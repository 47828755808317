import React from 'react';
import AboutUs from './AboutUs';
import Hours from './Hours';
import Curriculum from './Curriculum';
import Access from './Access';
import Belongings from './Belongings';
import Events from './Events';
import Trial from './Trial';

function Home() {
  return (
    <div>
      <section id="top-image">
        <img src="/images/top.jpg" alt="Top" className="w-full hidden sm:block" />
        <img src="/images/topS.jpeg" alt="Top" className="w-full sm:hidden h-screen object-cover" />
      </section>
      <div className="container mx-auto max-w-screen-xl p-4 mt-10">
        <Trial />
        <AboutUs />
        <Hours />
        <Curriculum />
        <Belongings />
        <Events />
        <Access />
      </div>
    </div>
  );
}

export default Home;
