import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-green-600 text-white p-4 fixed w-full z-10 top-0">
      <div className="container mx-auto flex justify-between items-center max-w-screen-xl">
        <div>
          <Link to="/"><h1 className="text-4xl font-bold">江戸千家 渭白流</h1></Link>
          <p className="hidden md:block text-lg">Official Website of Edo Senke Ihaku School, Tea Ceremony Class Information</p>
          <p className="block md:hidden text-sm">Official Website of Edo Senke<br />Ihaku School, Tea Ceremony Class Information</p>
        </div>
        <button
          className="md:hidden text-4xl focus:outline-none"
          onClick={toggleMenu}
        >
          {isOpen ? '✕' : '☰'}
        </button>
        <nav className="hidden md:flex">
          <Link smooth to="/#about" className="mr-4">About Us</Link>
          <Link smooth to="/#hours" className="mr-4">Hours</Link>
          <Link smooth to="/#curriculum" className="mr-4">Curriculum</Link>
          <Link smooth to="/#belongings" className="mr-4">Belongings</Link>
          <Link smooth to="/#events" className="mr-4">Events</Link>
          <Link smooth to="/#access" className="mr-4">Access</Link>
        </nav>
      </div>
      {isOpen && (
        <div className="fixed inset-0 bg-green-600 flex flex-col justify-center items-center text-4xl">
          <button
            className="absolute top-4 right-4 text-4xl focus:outline-none"
            onClick={toggleMenu}
          >
            ✕
          </button>
          <Link smooth to="/#about" className="my-4 no-underline hover:underline" onClick={toggleMenu}>About Us</Link>
          <Link smooth to="/#hours" className="my-4 no-underline hover:underline" onClick={toggleMenu}>Hours</Link>
          <Link smooth to="/#curriculum" className="my-4 no-underline hover:underline" onClick={toggleMenu}>Curriculum</Link>
          <Link smooth to="/#belongings" className="my-4 no-underline hover:underline" onClick={toggleMenu}>Belongings</Link>
          <Link smooth to="/#events" className="my-4 no-underline hover:underline" onClick={toggleMenu}>Events</Link>
          <Link smooth to="/#access" className="my-4 no-underline hover:underline" onClick={toggleMenu}>Access</Link>
        </div>
      )}
    </header>
  );
}

export default Header;
