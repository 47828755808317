import React from 'react';

function Hours() {
  return (
    <section id="hours" className="mb-20 pt-28 -mt-28">
      <h2 className="font-semibold text-4xl mb-4 text-center md:text-left">Hours</h2>
      <div className="p-4 bg-white shadow-md rounded-md">
        <table className="table-auto w-full text-center border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 border border-gray-300"></th>
              <th className="bg-purple-600 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">Monday</span>
                <span className="md:hidden">Mon</span>
              </th>
              <th className="bg-purple-600 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">Tuesday</span>
                <span className="md:hidden">Tue</span>
              </th>
              <th className="bg-purple-600 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">Wednesday</span>
                <span className="md:hidden">Wed</span>
              </th>
              <th className="bg-purple-600 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">Thursday</span>
                <span className="md:hidden">Thu</span>
              </th>
              <th className="bg-purple-600 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">Friday</span>
                <span className="md:hidden">Fri</span>
              </th>
              <th className="bg-blue-600 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">Saturday</span>
                <span className="md:hidden">Sat</span>
              </th>
              <th className="bg-red-600 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">Sunday</span>
                <span className="md:hidden">Sun</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="font-semibold px-4 py-2 border border-gray-300">昼の部<br/><span className="text-sm font-normal">13:00〜17:00</span></td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">⚪︎</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">⚪︎</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">⚪︎</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
            </tr>
            <tr>
              <td className="font-semibold px-4 py-2 border border-gray-300">夜の部<br/><span className="text-sm font-normal">18:00〜21:00</span></td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">⚪︎</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">⚪︎</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default Hours;
