import React from 'react';

const events = [
  {
    title: "渭白会",
    location: "護国寺境内茶室（文京区）",
    participants: "全員",
    schedule: "毎年1回（不定期）",
    time: "8:00 〜 15:00",
    cost: "15,000円",
    notes: "※諸経費（水屋費など）を含みます。",
  },
  {
    title: "初釜",
    location: "家元道場（稽古場）",
    participants: "全員",
    schedule: "毎年1月の第2日曜日",
    time: "10:00 〜 14:00",
    cost: "30,000円",
    notes: "※諸経費（水屋費など）を含みます。",
  },
  {
    title: "総会",
    location: "家元道場（稽古場）",
    participants: "全員",
    schedule: "毎年7月の第2 or 3日曜日",
    time: "10:00 〜 14:00",
    cost: "10,000円",
    notes: "※諸経費（水屋費など）を含みます。",
  },
  {
    title: "納会",
    location: "家元道場（稽古場）",
    participants: "全員",
    schedule: "毎年12月の第2 or 3日曜日",
    time: "10:00 〜 14:00",
    cost: "10,000円",
    notes: "※諸経費（水屋費など）を含みます。",
  },
  {
    title: "研究会",
    location: "家元道場（稽古場）",
    participants: "師範以上",
    schedule: "毎年4回程度（不定期）",
    time: "10:00 〜 14:00",
    cost: "6,000円",
    notes: "※諸経費（水屋費など）を含みます。",
  },
];

const renderEvents = (events) => {
  return events.map((event, index) => (
    <div key={index} className="p-4 bg-white shadow-md rounded-md">
      <h3 className="bg-red-600 text-white font-semibold px-4 py-2 mb-2 rounded">{event.title}</h3>
      <table className="min-w-full leading-normal border border-gray-300">
        <tbody>
          <tr>
            <td className="px-4 py-2 bg-gray-200 border border-white">場所</td>
            <td className="px-4 py-2 bg-white border border-gray-300">{event.location}</td>
          </tr>
          <tr>
            <td className="px-4 py-2 bg-gray-200 border border-white">参加者</td>
            <td className="px-4 py-2 bg-white border border-gray-300">{event.participants}</td>
          </tr>
          <tr>
            <td className="px-4 py-2 bg-gray-200 border border-white">日程</td>
            <td className="px-4 py-2 bg-white border border-gray-300">{event.schedule}</td>
          </tr>
          <tr>
            <td className="px-4 py-2 bg-gray-200 border border-white">時間</td>
            <td className="px-4 py-2 bg-white border border-gray-300">{event.time}</td>
          </tr>
          <tr>
            <td className="px-4 py-2 bg-gray-200 border border-white">費用</td>
            <td className="px-4 py-2 bg-white border border-gray-300">{event.cost}</td>
          </tr>
        </tbody>
      </table>
      <p className="text-sm mt-2">{event.notes}</p>
    </div>
  ));
};

function Events() {
  return (
    <section id="events" className="mb-20 pt-28 -mt-28">
      <h2 className="font-semibold text-4xl mb-4 text-center md:text-left">Events</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {renderEvents(events)}
      </div>
    </section>
  );
}

export default Events;
