import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function Trial() {
  return (
    <section id="trial" className="mb-20 pt-28 -mt-28">
      <h2 className="font-semibold text-4xl mb-4 text-center md:text-left">無料体験入門実施中！</h2>
      <div className="p-4 bg-yellow-50 shadow-md rounded-md">
        <p className="text-lg">
          茶道の世界に初めて触れる方のために、無料体験入門を実施しています。下記の詳細をご覧いただき、ぜひご参加ください。
        </p>
        <ul className="list-disc ml-5 mt-4">
          <li><strong>料金：</strong>無料</li>
          <li><strong>日時：</strong>メールにてご相談（<a href="mailto:juntailangantian997@gmail.com" className="text-blue-600 underline">juntailangantian997@gmail.com</a>）<br/>
            <span className="text-sm text-gray-600">※原則、<Link to="#hours" className="text-blue-600 underline">稽古日</Link>のいづれか</span>
          </li>
          <li><strong>場所：</strong><Link to="#access" className="text-blue-600 underline">道場</Link></li>
          <li><strong>服装：</strong>自由（白いソックスのみご持参ください）</li>
          <li><strong>持ち物：</strong>とくになし</li>
          <li><strong>内容：</strong>入門者のお稽古にお客さまとしてご参加いただき、お茶とお菓子をお召し上がりください<br/>
            <span className="text-sm text-gray-600">※お点前の体験はございません</span>
          </li>
        </ul>
        <p className="mt-4 text-sm text-red-600">※入門を検討している方のみご参加ください</p>
      </div>
    </section>
  );
}

export default Trial;
