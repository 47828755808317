import React from 'react';

const belongingsItems = [
  { name: "点前袱紗（てまえぶくさ）", description: "茶道具を拭くときに使ったりします", image: "temae_fukusa.jpeg" },
  { name: "小袱紗（こぶくさ）", description: "茶道具の下に敷いて使ったりします", image: "kobukusa.jpeg" },
  { name: "懐紙（かいし）", description: "お皿やティッシュの役目をします", image: "kaishi.jpeg" },
  { name: "楊枝（ようじ）", description: "茶菓子を食べるときだけ使います", image: "yoji.jpeg" },
  { name: "扇子（せんす）", description: "お辞儀するときに使います", image: "sensu.jpeg" },
  { name: "替足袋（かえたび）", description: "白いソックスでいいです", image: "" },
];

const renderBelongingsItems = (items) => {
  return items.map((item, index) => (
    <div key={index} className="mb-4 flex flex-col md:flex-row items-start">
      <img
        src={`/images/${item.image}`}
        alt={item.name}
        className="w-full md:w-96 h-64 object-cover rounded-md"
      />
      <div className="ml-0 md:ml-4 mt-4 md:mt-0 flex-grow">
        <div className="flex flex-col md:flex-row justify-between items-start md:block">
          <p className="text-lg font-semibold">{item.name}</p>
          <p className="text-sm md:text-right mt-2 md:mt-0">{item.description}</p>
        </div>
        <div className="border-b border-dotted border-gray-400 mt-2 mb-8"></div>
      </div>
    </div>
  ));
};

function Belongings() {
  return (
    <section id="belongings" className="mb-20 pt-28 -mt-28">
      <h2 className="font-semibold text-4xl mb-4 text-center md:text-left">Belongings</h2>
      <div className="p-4 bg-white shadow-md rounded-md">
        {renderBelongingsItems(belongingsItems)}
        <p className="text-sm mt-4">・初回は「白いソックス」だけで結構です。他は教室で用意あります。</p>
      </div>
    </section>
  );
}

export default Belongings;
